<template>
  <div>
    <van-nav-bar title="盘点" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <div class="d_form">
    <van-form>
      <van-cell-group inset>
        <van-field v-model="code" label="编号" readonly />
        <van-field v-model="time" label="日期" readonly />
        <van-field v-model="warehouse" label="仓库" />
        <van-field v-model="area" label="库区" />
        <div style="height: 1px; border-top: 1px solid black"></div>
        <div class="flex scan">
          <div style="width: 90%;"><van-field v-model="s" label="物料扫码" /></div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item title="物料列表" name="1">
            <div class="table-container">
              <table class="wide-table2">
                <tr>
                  <th>序号</th>
                  <th>物料名称</th>
                  <th>物料编码</th>
                  <th>库存数量</th>
                  <th>扫描数量</th>
                  <th>状态</th>
                  <th>E看板编码</th>
                  <th>供应商编码</th>
                  <th>批次</th>
                </tr>
                <tr v-for="user in list2" :key="user.id">
                  <td>{{ user.id }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.code }}</td>
                  <td>{{ user.number }}</td>
                  <td>{{ user.scanNumber }}</td>
                  <td>{{ user.status }}</td>
                  <td>{{ user.Ekanban }}</td>
                  <td>{{ user.providerCode }}</td>
                  <td>{{ user.lot }}</td>
                </tr>
              </table>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div style="margin: 16px;">
          <van-button block type="primary" size="small" native-type="submit" :disabled="flag" @click="onSubmit">
            确定
          </van-button>
        </div>
      </van-cell-group>

      <div v-if="flag === true" style="margin: 16px;">
        <van-button block type="primary" size="small" native-type="submit">
          确定
        </van-button>
      </div>
      <div style="height: 80px"></div>
    </van-form>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router"
import { Toast } from 'vant';
import { ref } from 'vue'
const router = useRouter()
const activeName = ref(1)
const code = ref('take1')
const warehouse = ref('成品区')
const time = ref('2024-08-17')
const list2= ref([
  {
    id: 1,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '正常',
    jig: '01',
    lot: 1,
    scanNumber: 10
  },
  {
    id: 2,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '盘少',
    jig: '01',
    lot: 1,
    scanNumber: 11
  },
  {
    id: 3,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '盘多',
    jig: '01',
    lot: 1,
    scanNumber: 9
  }
])
const loading = ref(false);
const finished = ref(false);
const onLoad = () => {
};
function back() {
  router.push({ path: "/index" })
}
</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}
.fz {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}
.fz div{
  width: 100px;
  display: flex;
  font-size: 0.3rem;
  margin-right: 10px;
}
.all{
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.flex{
  display: flex;
  width: 100%;
  overflow: scroll;
  justify-content: space-between;
}
</style>
